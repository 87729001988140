// @ts-nocheck
import Icon, {
  FileTextOutlined,
  FolderOpenOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import { Menu, Tag } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { PERMS_KEYS } from 'constants/perms.constants'
import { trackEvent } from 'helpers/posthog'
import { observer } from 'mobx-react'
import { BsBriefcase } from 'react-icons/bs'
import { FaRegDotCircle } from 'react-icons/fa'
import { IoLibraryOutline } from 'react-icons/io5'
import {
  MdDisplaySettings,
  MdOutlineFileCopy,
  MdOutlinePeople,
} from 'react-icons/md'
import styled from 'styled-components'
import styles from '../../../../styles'

const TrackNavigation = (label: string) => {
  if (label === '360 View') {
    trackEvent(`Navigation ${label}`)
  }
}

const onNav = ({
  route,
  label,
  isAvailable,
}: {
  route: string
  label: string
  isAvailable: boolean
}) => {
  return (/* info: MenuInfo */) => {
    if (!isAvailable) {
      global.perms.setUpgradeModalVisible(true)
      return
    }
    TrackNavigation(label)
    global.router.goto(route)
  }
}

const StyledMenu = styled(Menu)`
  background: transparent;
  border: none;

  .ant-menu-item {
    padding-left: 24px;
    padding-right: 16px;
  }
`

const UpgradeTag = styled(Tag)<{ bordered?: boolean }>`
  margin-inline: auto 0px;
  margin-top: -2px;

  /* Remove when migrate to antd v5 */
  ${({ bordered }) => !bordered && 'border-color: transparent;'}
`

const LabelWithUpgradeTag = ({ label }: { label: string }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {label}
      <UpgradeTag color={styles.colors.click} bordered={true}>
        Pro
      </UpgradeTag>
    </div>
  )
}

const SideMenu = () => {
  const items: ItemType[] = [
    ...(Boolean(global.perms?.get(PERMS_KEYS.CONTACT))
      ? [
          {
            key: 'contacts',
            label: global.perms.isAvailable(PERMS_KEYS.CONTACT) ? (
              'Contacts'
            ) : (
              <LabelWithUpgradeTag label="Contacts" />
            ),
            title: 'Contacts',
            icon: <Icon component={MdOutlinePeople} />,
            onClick: onNav({
              route: '/contacts',
              label: 'Contacts',
              isAvailable: Boolean(
                global.perms.isAvailable(PERMS_KEYS.CONTACT),
              ),
            }),
            // TODO: improve data-tour labeling
            // @ts-ignore
            'data-tour': 'Menu-Contacts',
          },
        ]
      : []),
    ...(global.data.advisors.isAdmin ||
    Boolean(global.perms?.get(PERMS_KEYS.CAPTURE_GENIUS))
      ? [
          {
            key: 'capture-genius',
            label:
              global.data.advisors.isAdmin ||
              global.perms.isAvailable(PERMS_KEYS.CAPTURE_GENIUS) ? (
                'CaptureGenius'
              ) : (
                <LabelWithUpgradeTag label="CaptureGenius" />
              ),
            title: 'CaptureGenius',
            icon: <Icon component={FaRegDotCircle} />,
            onClick: onNav({
              route: '/capture-genius',
              label: 'CaptureGenius',
              isAvailable: Boolean(
                global.data.advisors.isAdmin ||
                  global.perms.isAvailable(PERMS_KEYS.CAPTURE_GENIUS),
              ),
            }),
            // TODO: improve data-tour labeling
            // @ts-ignore
            'data-tour': 'Menu-CaptureGenius',
          },
        ]
      : []),
    ...(Boolean(global.perms?.get(PERMS_KEYS.VIEW360))
      ? [
          {
            key: 'reports',
            label: global.perms.isAvailable(PERMS_KEYS.VIEW360) ? (
              '360 View'
            ) : (
              <LabelWithUpgradeTag label="360 View" />
            ),
            title: '360 View',
            icon: <ScheduleOutlined />,
            onClick: onNav({
              route: '/reports',
              label: '360 View',
              isAvailable: Boolean(
                global.perms.isAvailable(PERMS_KEYS.VIEW360),
              ),
            }),
            // TODO: improve data-tour labeling
            // @ts-ignore
            'data-tour': 'Menu-360View',
          },
        ]
      : []),

    {
      key: 'notes',
      label: 'Meeting Workspace',
      title: 'Meeting Workspace',
      icon: <Icon component={BsBriefcase} />,
      onClick: onNav({
        route: '/notes/client',
        label: 'Meetings',
        isAvailable: true,
      }),
      // TODO: improve data-tour labeling
      //@ts-ignore
      'data-tour': 'Menu-Meetings',
    },
    ...(Boolean(global.perms?.get(PERMS_KEYS.LIBRARY))
      ? [
          {
            key: 'library',
            label: 'Library',
            title: 'Library',
            icon: <Icon component={IoLibraryOutline} />,
            onClick: onNav({
              route: '/library',
              label: 'Library',
              isAvailable: true,
            }),
            // TODO: improve data-tour labeling
            //@ts-ignore
            'data-tour': 'Menu-Meetings',
          },
        ]
      : []),
    ...(Boolean(global.perms?.get(PERMS_KEYS.DOCUMENTS))
      ? [
          {
            key: 'documents',
            label: global.perms.isAvailable(PERMS_KEYS.DOCUMENTS) ? (
              'Documents'
            ) : (
              <LabelWithUpgradeTag label="Documents" />
            ),
            title: 'Documents',
            icon: <FileTextOutlined />,
            onClick: onNav({
              route: '/documents',
              label: 'Documents',
              isAvailable: Boolean(
                global.perms.isAvailable(PERMS_KEYS.DOCUMENTS),
              ),
            }),
            // TODO: improve data-tour labeling
            // @ts-ignore
            'data-tour': 'Menu-Documents',
          },
        ]
      : []),

    ...(Boolean(global.perms?.get(PERMS_KEYS.TEMPLATE))
      ? [
          {
            key: 'templates',
            label: global.perms.isAvailable(PERMS_KEYS.TEMPLATE) ? (
              'Templates'
            ) : (
              <LabelWithUpgradeTag label="Templates" />
            ),
            title: 'Templates',
            icon: <Icon component={MdOutlineFileCopy} />,
            onClick: onNav({
              route: '/templates',
              label: 'Templates',
              isAvailable: Boolean(
                global.perms.isAvailable(PERMS_KEYS.TEMPLATE),
              ),
            }),
            // TODO: improve data-tour labeling
            // @ts-ignore
            'data-tour': 'Menu-Templates',
          },
        ]
      : []),

    ...(Boolean(global.perms?.get(PERMS_KEYS.FILE))
      ? [
          {
            key: 'vault',
            label: global.perms.isAvailable(PERMS_KEYS.FILE) ? (
              'Files'
            ) : (
              <LabelWithUpgradeTag label="Files" />
            ),
            title: 'Files',
            icon: <FolderOpenOutlined />,
            onClick: onNav({
              route: '/vault',
              label: 'Files',
              isAvailable: Boolean(global.perms.isAvailable(PERMS_KEYS.FILE)),
            }),
            // TODO: improve data-tour labeling
            // @ts-ignore
            'data-tour': 'Menu-Files',
          },
        ]
      : []),

    {
      key: 'onboard',
      label: 'Setup',
      title: 'Setup',
      icon: <Icon component={MdDisplaySettings} />,
      onClick: onNav({ route: '/onboard', label: 'Setup', isAvailable: true }),
      // TODO: improve data-tour labeling
      // @ts-ignore
      'data-tour': 'Menu-Setup',
    },
  ]

  return (
    <StyledMenu
      mode="inline"
      items={items}
      theme="light"
      selectedKeys={[global.router.pathname.split('/')[0]]}
    />
  )
}

export default observer(SideMenu)
